import { createSlice } from "@reduxjs/toolkit";
import { AdsStateType } from "./interface";

const initialState: AdsStateType = {
  modal: null,
  showModal: false,
};

export const adsSlice = createSlice({
  name: "dailyReward",
  initialState,
  reducers: {
    setAdsModal: (state, action) => {
      state.modal = action.payload.modal;
    },
    setShowModalAds: (state, action) => {
      state.showModal = action.payload;
    },
    clearAds: (state) => {
      state.modal = null;
    },
  },
});

export const { setAdsModal, setShowModalAds, clearAds } = adsSlice.actions;
export default adsSlice.reducer;
