export const WebSocketsServerEvents = {
  CONNECT: "connect",
  DAILY_REWARD:"daily_reward",
  ADS:"ads",
  UPDATE_BALANCE: "update_balance",
  HOUSE_BUILD_PROGRESS: "house_build_progress",
  SHIP_TRAVEL_UPDATE: "ship_travel_update",
  TASK_STATUS: "task_status",
  TASK_COMPLETED: "task_completed",
  BUILD_UPGRADE: "build_upgrade",
  RESOURCE_COLLECTED: "resource_collected",
  CHESTS_COLLECTED: "chests",
  PURCHASE: "purchase",
  BOOSTER_UPDATE: "booster_update",
  ACTUALIZED_STATS: "actualized_stats",
  HOUSE_UPDATE: "house_update",
  POPUP:'popUp',

};

export const WebSocketsClientEvents = {
  BOOSTER_COLLECT:"booster_collect",
  ADS_CHECKED:"ads_checked",
  ADS_COMPLETE:"collect_ads_water_chest",
  COLLECT_DAILY_REWARD:"collect_daily_reward",
  UPGRADE_HOUSE: "build_upgrade",
  PURCHASE_RECEIVED:"purchase_received",
  COLLECT_RESOURCES_GOLD: "collect_resources",
  SHIP_SENT_TRAVEL: "ship_sent",
  COMPLETE_QUEST: "task_completed",
  CONNECT_WALLET: "wallet_connect",
  DISCONNECT_WALLET: "wallet_disconnect",
  FILLED_RECOURCES: "cheatCanCollect",
  GET_CHESTS: "get_chests",
  TUTORIAL_CHESTS: "tutorial_chest",
  PURCHASE: "purchase",
  CLAIM_CHEST_REWARD: "claim_chest_reward",
  KEY_REMOVE: "key_remove",
  AUTO_COLLECT: "auto_collect",
  PEARL_COLLECT: "collect_pearls",
  KEY_COLLECT:"collect_keys",
  TUTORIAL_HOUSE_BUILD: "tutorial_house_build",
  ACUTALIZED_DATA: "actualized_data",
  CHANGE_FIRST_EXP: "change_first_exp",
  BUY_KEY: "buy_key",
  SHOW_POPUP:"show_popup",
    SUPER_BOOSTER:'super_auto_collect',
  NOTIFICATION:"notification"
};
