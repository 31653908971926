import { createSlice } from "@reduxjs/toolkit";
import { DayilyRewardStateType } from "./interface";

const initialState: DayilyRewardStateType = {
  day_reward: null,
  rewards: null,
};
export const dailyRewardSlice = createSlice({
  name: "dailyReward",
  initialState,
  reducers: {
    setDailyReward: (state, action) => {
      state.day_reward = action.payload.day_reward;
      state.rewards = action.payload.rewards;
    },
    clearDailyReward: (state) => {
      state.day_reward = null;
    },
  },
});

export const { setDailyReward, clearDailyReward } = dailyRewardSlice.actions;
export default dailyRewardSlice.reducer;
